import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import HTMLContent from "../components/Content"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import { loupify } from "../utilities/loupe"
import { snippetify } from "../utilities/snippet"

export class ArticleContent extends React.Component {

  propTypes = {
    content: PropTypes.node,
    getSnippet: PropTypes.func,
    id: PropTypes.string,
    title: PropTypes.string,
  }

  componentDidMount () {
    loupify()
    snippetify(this.props.getSnippet)
  }

  render () {
    const {
      id,
      title,
      content,
    } = this.props
    
    return (
      <section
        className="article"
        key={id}
      >
        <h2 className="article-title">{title}</h2>
        <HTMLContent className="article-content" content={content} />
      </section>
    )
  }
}

const ArticleTemplate = ({ data, pageContext: { id } }) => {
  const { markdownRemark: page } = data
  const {
    frontmatter: {
      title,
      reuse,
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    },
    fields: { collection },
  } = page

  let { html } = page

  if (reuse) {
    // Replace the HTML with the re-used general HTML
    const found = data.general.edges
      .find(({ node: { fields: { slug } } }) => slug.indexOf(`/general/${reuse}`) === 0)
    if (found) {
      html = found.node.html
    }
  }

  let groups = []

  if (collection) {
    const foundNavbarCollection = data.navbarData.edges
      .find(({ node: { frontmatter: { collection: navbarCollection } } }) => navbarCollection === collection)
    if (foundNavbarCollection) {
      groups = foundNavbarCollection
        .node.frontmatter.folders.reduce((result, { label, group }) => {
          result.push({
            label,
            articles: data.allArticles.edges
              .filter(({ node: { fields: { slug } } }) => slug.indexOf(`/${collection}/${group}-`) === 0)
              .map(({ node: { frontmatter: { title }, fields: { slug } } }) => ({ title, slug })),
          })
          return result
        }, [])
    }
  }

  const getSnippet = (slug) => data.snippets.edges
    .find(({ node: { fields: { slug: snippetSlug } } }) => `/snippets/${slug}/` === snippetSlug)
    .node
    .html

  return (
    <Layout
      footerData={data.footerData}
      navbarData={{ groups }}
    >
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <ArticleContent
        title={title}
        content={html}
        id={id}
        getSnippet={getSnippet}
      />
    </Layout>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ArticleTemplate

export const query = graphql`
  query Article($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
        collection
      }
      frontmatter {
        title
        reuse
        seo {
          browserTitle
          title
          description
        }
      }
    }
    snippets: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "Snippet" } } }) {
      edges {
        node {
          html
          fields {
            slug
          }
        }
      }
    }
    general: allMarkdownRemark(filter: {
      fields: { collection: { eq: "general" } },
    }) {
      edges {
        node {
          html
          fields {
            slug
          }
        }
      }
    }
    ...LayoutFragment
  }
`
