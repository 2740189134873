const MAX_DEPTH = 5

const resolveSnippet = (getSnippet, currDepth) => (element) => {
  const name = element.getAttribute('data-snippet')
  const htmlNode = document.createElement('div')
  htmlNode.innerHTML = getSnippet(name)
  if (currDepth > 0) {
    snippetifyEach(getSnippet, htmlNode, currDepth - 1)
  }
  element.appendChild(htmlNode)
}

const snippetifyEach = (getSnippet, element, currDepth) => element
  .querySelectorAll('.slayte-snippet').forEach(resolveSnippet(getSnippet, currDepth))

export const snippetify = function (getSnippet) {
	snippetifyEach(getSnippet, document, MAX_DEPTH)
}
