const addLoupe = function(originalImage) {
  const wrapper = document.createElement('div')
  wrapper.setAttribute('data-loupe-wrap', '')

  const loupe = document.createElement('div')
  loupe.setAttribute('data-loupe', '')

  const enlarged = document.createElement('img')
  enlarged.src = originalImage.src
  enlarged.setAttribute('data-loupe-enlarged', '')

  const showLoupe = function () {
		wrapper.style.zIndex = '1'
		loupe.style.opacity = '1'
	}

	const hideLoupe = function () {
		wrapper.style.zIndex = '0'
		loupe.style.opacity = '0'
  }
  
  const moveLoupe = function (event) {
		window.requestAnimationFrame(() => {
			const originalClientRect = originalImage.getBoundingClientRect()

			const cursorX = event.x + originalImage.offsetLeft - originalClientRect.left
			const cursorY = event.y + originalImage.offsetTop - originalClientRect.top

			const enlargedX = enlarged.width * (cursorX - originalImage.offsetLeft) / originalClientRect.width - loupe.offsetWidth / 2
			const enlargedY = enlarged.height * (cursorY - originalImage.offsetTop) / originalClientRect.height - loupe.offsetHeight / 2

			const loupeX = cursorX - loupe.offsetWidth / 2
			const loupeY = cursorY - loupe.offsetHeight / 2

			enlarged.style.transform = `translate3d(-${enlargedX}px, -${enlargedY}px, 0)`
			loupe.style.transform = `translate3d(${loupeX}px, ${loupeY}px, 0)`
		})
	}

  loupe.appendChild(enlarged)
  wrapper.appendChild(loupe)
  originalImage.parentNode.insertBefore(wrapper, originalImage)
	wrapper.appendChild(originalImage)
	
	// Hide the cursor
	originalImage.style.cursor = 'none'

  originalImage.addEventListener('mouseenter', showLoupe)
  originalImage.addEventListener('mousemove',  moveLoupe)
  originalImage.addEventListener('mouseleave', hideLoupe)
}

export const loupify = function () {
	document.querySelectorAll('.slayte-loupe').forEach(addLoupe)
}
